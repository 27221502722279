import React from 'react';
import LineGraph from '../LineGraph';

export default function ListingsClaimedGraph({ data }) {
  return (
    <LineGraph
      data={data}
      title="Daily Listings Claimed"
      dataKey="count"
      name="Listings Claimed"
      strokeColor="#8884d8" // Blue color
      tooltipContentStyle={{
        borderRadius: '0.5rem',
        padding: '0.5rem',
        backgroundColor: 'rgba(0, 0, 0, 0.95)',
      }}
    />
  );
}