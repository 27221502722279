import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

export default function LineGraph({
  data,
  title,
  dataKey,
  name,
  strokeColor,
  tooltipContentStyle,
}) {
  return (
    <div className="flex flex-col flex-wrap min-w-96 max-w-full bg-primary rounded-lg p-4 flex-grow">
      <h2 className="text-lg font-semibold mb-2">{title}</h2>
      {data && data.length > 0 ? (
        <ResponsiveContainer height={300} width='100%'>
          <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis allowDecimals={false} />
            <Tooltip
              reverseDirection={false}
              useTranslate3d={false}
              contentStyle={tooltipContentStyle}
            />
            <Legend />
            <Line
              dataKey={dataKey}
              name={name}
              stroke={strokeColor}
              activeDot={{ r: 8 }}
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <p className="text-center">No data available for the selected period.</p>
      )}
    </div>
  );
}