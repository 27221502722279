import { useRef, useState, useEffect, useCallback } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

// Haversine formula to calculate distance between two lat/lng points in miles
const haversineDistance = (lat1, lng1, lat2, lng2) => {
  const toRad = (value) => (value * Math.PI) / 180;
  const R = 3958.8; // Earth radius in miles
  const dLat = toRad(lat2 - lat1);
  const dLng = toRad(lng2 - lng1);
  const a = Math.sin(dLat / 2) ** 2 + Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLng / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

export default function MapComponent({ zoom, filteredListings, setFilteredListings, center, setCenter, isMapMode, circleRef, setFilteredVIds }) {
  const [map, setMap] = useState(null);
  const circleRadiusRef = useRef(0);
  const circleCenterRef = useRef(null);
  const markersRef = useRef([]);

  const twClass = { focused: 'animate-pulseShadow' };

  useEffect(() => {
    if (circleRef.current !== null) circleRef.current.setMap(null);
    if (isMapMode) {
      setFilteredVIds([]);
    }
    else {
      updateCircle(circleCenterRef.current);

      // Find markers within radius
      const markersWithinRadius = markersRef.current.filter(marker => {
        const markerPosition = marker.marker.position;
        const distanceFromCenter = haversineDistance(circleCenterRef.current.lat, circleCenterRef.current.lng, markerPosition.lat, markerPosition.lng);
        const isWithinRadius = distanceFromCenter <= circleRadiusRef.current;
        return isWithinRadius;
      });
  
      // Extract v_ids directly into a flat array
      const v_ids = markersWithinRadius.map(m => m.v_id);

      // console.log('v_ids,', v_ids)
      setFilteredVIds(v_ids);
  
      setFilteredListings(prevListings => {
        const newListings = prevListings.filter(l => {
          return v_ids.includes(l.key);
        });
        // console.log('newListings:', newListings);
        return newListings;
      });
    }

  }, [isMapMode]);

  const updateCircle = (position) => {
    if (isMapLoaded) {
      circleRadiusRef.current = calculateVerticalDistance() / 2;
      const r = circleRadiusRef.current * 1609.34; // Convert miles to meters

      // Remove the previous circle if it exists
      if (circleRef.current) {
        circleRef.current.setMap(null);
      }

      const newCircle = new google.maps.Circle({
        center: position,
        map: map,
        radius: r,
        strokeColor: '#00bf63',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#00bf63',
        fillOpacity: 0.2,
      });
      circleRef.current = newCircle;
    }
  };

  const handleMarkerClick = useCallback((listing) => {
    if (listing.data.platform !== 'craigslist') return;

    const newCenter = { lat: Number(listing.data.lat), lng: Number(listing.data.lng) };
    if (center.lat !== newCenter.lat || center.lng !== newCenter.lng) {
      setCenter(newCenter);
    }

    const element = document.getElementById(`listing-row-${listing.key}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      element.classList.add(...twClass.focused.split(' '));

      element.addEventListener('animationend', () => {
        element.classList.remove(...twClass.focused.split(' '));
      }, { once: true });
    }
  }, [center, setCenter]);

  // Handle Map Loading
  const { isLoaded: isMapLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
  });

  useEffect(() => {
    const loadMarkers = async () => {
      if (map !== null) {
        /* global google */
        const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');

        // Remove existing markers from the map
        markersRef.current?.forEach(marker => marker.marker?.setMap(null));
        markersRef.current = [];

        // Create new markers and store them in the ref
        filteredListings.forEach(listing => {
          const marker = new AdvancedMarkerElement({
            position: { lat: Number(listing.data.lat), lng: Number(listing.data.lng) },
            map: map
          });

          // Add a click event listener to each marker
          marker.addListener('click', () => handleMarkerClick(listing));
          markersRef.current.push({ marker, v_id: listing.key });
        });
      }
    };

    loadMarkers(); // Call the async function
  }, [map, filteredListings, handleMarkerClick]);

  // Calculate the map's vertical distance (top to bottom in miles)
  const calculateVerticalDistance = useCallback(() => {
    if (map) {
      const bounds = map.getBounds();
      if (bounds) {
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        const topLat = northEast.lat();
        const bottomLat = southWest.lat();
        const lng = (northEast.lng() + southWest.lng()) / 2;

        return haversineDistance(topLat, lng, bottomLat, lng);
      }
    }
    return 0;
  }, [map]);

  if (!isMapLoaded) {
    return <div className="w-full p-4 flex items-center justify-center text-bone text-xl">Loading...</div>;
  }

  return (
    <div className="relative w-full min-h-[66.67vh] h-[66.67vh] rounded-lg overflow-hidden">
      {isMapMode && (
        <div className="absolute left-0 top-0 w-full h-full opacity-100 z-50 pointer-events-none">
          <div className="absolute h-full aspect-square rounded-full left-1/2 -translate-x-1/2 border-[1px] border-c-green-dark bg-c-green/30" />
        </div>
      )}
      <GoogleMap
        options={{ mapId: 'map' }}
        mapContainerClassName="w-full h-full"
        center={center}
        zoom={zoom}
        onLoad={mapInstance => setMap(mapInstance)}
        onDragEnd={() => {
          const newCenter = map.getCenter().toJSON();
          circleCenterRef.current = newCenter;
        }}
      >
      </GoogleMap>
    </div>
  );
}
