import { useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import AdTableRow from "./AdTableRow";
import LogoWork from '../img/logo-work.png'
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, logEvent, user } from "../firebase";


export default function AdTable({ listings, setCenter, setZoom, setIsMapCollapsed, formatTimeAgo, allListingsLoaded, updateListings, daysLoaded, setDaysLoaded, showArchived }) {
  const [sort, setSort] = useState({ sort: 'time_ago', desc: false });
  const [sortedListings, setSortedListings] = useState([]);
  const [lastArchived, setLastArchived] = useState(null);

  function capitalizeWords(str) {
    if (str === null || str === undefined || str === '') return 'N/A';
    return str.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase());
  }

  function sortListings(listings, newSort = sort) {
    return listings.sort((a, b) => {
      const comparison = compareValues(a.data[newSort.sort], b.data[newSort.sort]);
      return newSort.desc ? -comparison : comparison;
    });
  }

  function compareValues(a, b) {
    if (a === null || a === '') a = 0;
    if (b === null || b === '') b = 0;

    if (typeof a === 'string') a = a.toLowerCase();
    if (typeof b === 'string') b = b.toLowerCase();

    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  }

  const handleMapClick = (listing) => {
    setIsMapCollapsed(false);
    setCenter({ lat: Number(listing.data.lat), lng: Number(listing.data.lng) });
    setZoom(16);
    
    const requestSection = document.getElementById('request-section');
    if (requestSection) {
      const elementBottomPosition = requestSection.getBoundingClientRect().top;
      const offset = window.innerHeight - 60;
    
      // Scroll the page by the offset to align the element at the bottom
      window.scrollBy({
        top: offset,
        behavior: 'smooth'
      });
    }
  };

  const handleScroll = (e) => {
    if(!updateListings && !allListingsLoaded){
      const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
      if (bottom && !showArchived) {
        const timeoutId = setTimeout(() => {
          setDaysLoaded(daysLoaded+1);
        }, 1500);
        return () => clearTimeout(timeoutId);
      }
    }
  }

  function Th({ type, className='', label=null }) {
    const handleSortClick = () => {
      logEvent('sort', { sort: type });
      const newSort = sort.sort === type ? { sort: type, desc: !sort.desc } : { sort: type, desc: true };
      setSort(newSort);
      setSortedListings(sortListings(listings, newSort));
    };
  
    return (
      <th className={`rounded-md px-2 py-1 bg-primary border-b-2 ${className}`}>
        <button className='flex flex-row gap-1 items-center justify-center w-full' onClick={handleSortClick}>
          <p className={`text-nowrap ${sort.sort === type ? 'opacity-100' : 'opacity-75'}`}>{label === null ? capitalizeWords(type) : label}</p>
          <ChevronDownIcon 
            className={`w-5 h-5 transition duration-300 ${sort.sort === type ? (sort.desc ? 'rotate-0' : '-rotate-180') : 'rotate-0 opacity-75'}`} 
          />
        </button>
      </th>
    );
  }

  function handleUndoArchiveClick() {
    // Reference to the document in Firestore
    const listingDocRef = doc(db, 'listings', lastArchived.key);
    
    getDoc(listingDocRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          
          // Filter out the current user's archived data
          const updatedArchivedArray = data.archived.filter(u => u.uid !== user.uid);
          
          // Update Firestore document with filtered array
          updateDoc(listingDocRef, {
            ...data,
            archivedReasons: null,
            archived: updatedArchivedArray,
          })
          .then(() => {
            logEvent('unarchive', { listing: lastArchived.key });
            setLastArchived(null);
            
            // Hide undo popup
            const d = document.getElementById('archive-undo-popup');
            d.classList.add('opacity-0', 'pointer-events-none');
  
            // Show listing row
            const row = document.getElementById(`listing-row-${lastArchived.key}`);
            if (row) {
              row.classList.remove('hidden', 'opacity-0');
            }
          })
          .catch((error) => {
            console.error('Error updating document:', error);
          });
        }
      })
      .catch((error) => {
        console.error('Error fetching document:', error);
      });
  }

  useEffect(() => {
    const sorted = sortListings(listings);
    setSortedListings(sorted);
  }, [listings, sort]);

  useEffect(() => {
    if (lastArchived) {
      const undoPopup = document.getElementById('archive-undo-popup');
      undoPopup.classList.remove('pointer-events-none');
      undoPopup.classList.remove('opacity-0');

      let timeoutId = setTimeout(() => {
        undoPopup.classList.add('opacity-0');
        undoPopup.classList.add('pointer-events-none');
      }, 5000);

      const clearAndResetTimeout = () => {
        clearTimeout(timeoutId);
      };

      const resetTimeout = () => {
        timeoutId = setTimeout(() => {
          undoPopup.classList.add('opacity-0');
          undoPopup.classList.add('pointer-events-none');
        }, 5000);
      };

      undoPopup.addEventListener('mouseenter', clearAndResetTimeout);
      undoPopup.addEventListener('mouseleave', resetTimeout);

      return () => {
        undoPopup.removeEventListener('mouseenter', clearAndResetTimeout);
        undoPopup.removeEventListener('mouseleave', resetTimeout);
      };
    }
  }, [lastArchived])

  return (
    <div className="relative overflow-y-scroll overflow-x-auto w-full -mb-2 min-h-[84vh]" onScroll={handleScroll}>

      {/* Archive undo popup */}
      <div
        className={`duration-500 transition-opacity opacity-0 fixed bottom-8 z-50 left-1/2 -translate-x-1/2 px-4 py-2 bg-secondary
                  text-bone rounded-lg shadow-md flex ring-1 ring-tertiary items-center gap-2 pointer-events-none`}
        id="archive-undo-popup"
      >
        <p className="text-lg">Listing archived!</p>
        <button className="text-tertiary rounded-lg p-1" onClick={handleUndoArchiveClick}>Undo</button>
      </div>

      <table className="table-fixed border-separate border-spacing-[3px] relative text-bone">
        <thead className="sticky top-0 left-0 w-[100vw] z-20 bg-opacity-90">
          {/* Background element using pseudo-element styling */}
          <tr className="relative">
            <th className="bg-secondary absolute -left-4 inset-0 h-full w-full -z-10"></th>
            <th></th>
            <th></th>
            <th></th>
            <Th type="price" />
            <Th type="year" />
            <Th type="makemodel" className="w-full" />
            <Th type="miles" />
            <Th type="rv_type" />
            <Th type="city" />
            <Th type="state" />
            <Th type="time_ago" label="Posted" />
          </tr>
        </thead>

        <tbody>
          {sortedListings.length === 0
            ? (
              <tr>
                <td colSpan="12">
                  <div className="flex justify-center items-center">
                    <img src={LogoWork} alt='working' className='w-36' loading="lazy" />
                    <h2 className="text-2xl">
                      {showArchived
                        ? 'No archived listings'
                        : 'No listings to show. Try a broader search.'
                      }
                    </h2>
                  </div>
                </td>
              </tr>
            ) : sortedListings.map((listing) => (
              <AdTableRow
                key={listing.key}
                id={listing.key}
                listing={listing}
                formatTimeAgo={formatTimeAgo}
                handleMapClick={handleMapClick}
                capitalizeWords={capitalizeWords}
                setLastArchived={setLastArchived}
                showArchived={showArchived}
              />
            )
          )}
        </tbody>
      </table>
    </div>
  );
}
