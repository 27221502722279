import React from 'react';
import LineGraph from '../LineGraph';

export default function TotalLoginsGraph({ data }) {
  return (
    <LineGraph
      data={data}
      title="Daily Total Logins"
      dataKey="count"
      name="Total Logins"
      strokeColor="#82ca9d" // Green color
      tooltipContentStyle={{
        borderRadius: '0.5rem',
        padding: '0.5rem',
        backgroundColor: 'rgba(0, 0, 0, 0.95)',
      }}
    />
  );
}