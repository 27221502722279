import { useEffect, useRef, useState } from "react";
import { ReactComponent as Placeholder } from '../img/rv.svg';
import { ReactComponent as Link } from '../img/link.svg';
import { ReactComponent as Archive } from '../img/archive.svg';
import { ReactComponent as LockOpen } from '../img/lock-open.svg';
import { ReactComponent as LockClosed } from '../img/lock-closed.svg';
import clLogo from '../img/craigslist-logo.png';
import rvtLogo from '../img/rvt-logo.png';
import rvUniverseLogo from '../img/rvu.png';
import rvTraderLogo from '../img/rvtrader.png';
import { db, user, logEvent } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export default function AdTableRow({ listing, id, formatTimeAgo, handleMapClick, capitalizeWords, setLastArchived, showArchived }) {
  // ========================================
  // States
  // ========================================
  const [isViewed, setIsViewed] = useState(listing.data.viewed?.some(view => view.uid === user.uid));
  const [isClaimed, setIsClaimed] = useState(checkClaimed); // Options: 'self', 'other', false\
  const [isArchivePopupOpen, setIsArchivePopupOpen] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const didRenderRef = useRef(false);

  // ========================================
  // Functions
  // ========================================

  // ==== Claiming ====

  function checkClaimed() {
    if (listing.data.claimed !== undefined && listing.data.claimed?.length > 0) {
      if (listing.data.claimed.some(claim => claim.uid === user.uid)) {
        return 'self'
      } else if (listing.data.claimed.length > 0) {
        return 'other'
      }
      return false
    } else {
      return false;
    }
  }

  function handleClaimClick(listing) {
    const listingDocRef = doc(db, 'listings', listing.key);
    getDoc(listingDocRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          if (isClaimed) {
            updateDoc(listingDocRef, {
              ...docSnap.data(),
              claimed: docSnap.data().claimed.filter(u => u.uid !== user.uid),
            })
            .then(() => {
              setIsClaimed(false);
              logEvent('unclaim', { listing: listing.key });
            })
            .catch((error) => {
              console.error('Error updating document: ', error);
            });
          } else {
            updateDoc(listingDocRef, {
              ...docSnap.data(),
              claimed: docSnap.data().claimed
                ? [...docSnap.data().claimed, user]
                : [user],
            })
            .then(() => {
              setIsClaimed('self');
              logEvent('claim', { listing: listing.key });
            })
            .catch((error) => {
              console.error('Error updating document: ', error);
            });
          }
        }
      });
  }

  function handleClaimHover() {
    // Check if a uid is in the claimed array in firestore
    const listingDocRef = doc(db, 'listings', listing.key);
    getDoc(listingDocRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const claimedBy = docSnap.data().claimed;
          if (claimedBy && claimedBy.length > 0) {
            setIsClaimed(claimedBy.some(claim => claim.uid === user.uid) ? 'self' : 'other');
          } else {
            setIsClaimed(false);
          }
        }
      }).catch((error) => {
        console.error('Error fetching document: ', error);
      });
  }

  // ==== Archive ====

  const reasons = [
    "Sold",
    "Not an RV",
    "Duplicate",
    "Scam",
    "Make or Model",
    "Year",
    "Price",
    "Miles",
    "Condition",
    "Other",
  ];

  function handleArchiveClick(event) {
    event.preventDefault();

    if (showArchived) {
      const row = document.getElementById(`listing-row-${listing.key}`);
      if (row) row.classList.add('hidden');

      // Remove from archived array in Firestore
      const listingDocRef = doc(db, 'listings', listing.key);
      getDoc(listingDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            updateDoc(listingDocRef, {
              ...docSnap.data(),
              archivedReasons: null,
              archived: docSnap.data().archived.filter(u => u.uid !== user.uid),
            })
            .then(() => {
              setLastArchived(null);
              logEvent('unarchive', { listing: listing.key });
            })
            .catch((error) => {
              console.error('Error updating document: ', error);
            });
          }
        });
    }
    else setIsArchivePopupOpen(prev => !prev);
  }

  function handleArchiveSubmit(event) {
    event.preventDefault();

    setIsArchivePopupOpen(false);

    const listingDocRef = doc(db, 'listings', listing.key);

    getDoc(listingDocRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          updateDoc(listingDocRef, {
            ...docSnap.data(),
            archivedReasons: selectedReasons,
            archived: docSnap.data().archived
              ? [...docSnap.data().archived, user]
              : [user],
          })
          .then(() => {
            setLastArchived(listing);
            
            // Hide listing row
            const row = document.getElementById(`listing-row-${listing.key}`);
            if (row) {
              row.classList.add('hidden');
            }

            logEvent('archive', { listing: listing.key, reasons: selectedReasons });
          })
          .catch((error) => {
            console.error('Error updating document: ', error);
          });
        }
      });
  };

  const toggleReason = (reason) => {
    setSelectedReasons((prevSelected) =>
      prevSelected.includes(reason)
        ? prevSelected.filter((r) => r !== reason)
        : [...prevSelected, reason]
    );
  };


  // ==== Viewed ====

  function handleLinkClick(event, noOpen=true) {
    // Check if left-click or middle-click
    if (event.button === 0 || event.button === 1) {
      // Prevent default behavior for middle-click
      event.preventDefault();

      logEvent('link_click', { listing: listing.key, platform: listing.data.platform });
      
      // Open listing in new tab
      if (noOpen) window.open(listing.data.url, '_blank');
      
      // Add user to viewed array in Firestore if not already viewed
      const listingDocRef = doc(db, 'listings', listing.key);
  
      if (isViewed) return;
  
      getDoc(listingDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            updateDoc(listingDocRef, {
              ...docSnap.data(),
              viewed: docSnap.data().viewed
                ? [...docSnap.data().viewed, user]
                : [user],
            })
            .then(() => {
              setIsViewed(true);
              logEvent('view', { listing: listing.key });
            })
            .catch((error) => {
              console.error('Error updating document: ', error);
            });
          }
        });
    }
  }

  // ==== Formatting Text ====

  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  // Function to truncate description
  const truncateString = (description, maxLength) => {
    if (!description) return 'No description available';
    return description.length > maxLength ? description.substring(0, maxLength) + '...' : description;
  };

  // ==== Rendering images ====

  const imageUrlSmallRef = useRef(null);
  if (listing.data.platform === 'craigslist') imageUrlSmallRef.current = listing.data.image_urls?.find(url => url.includes('50x50')) || listing.data.image_urls?.[0] || null;
  else imageUrlSmallRef.current = listing.data.image_urls?.[0] || null;

  const platformImageRef = useRef(null);
  switch(listing.data.platform) {
    case 'craigslist':
      platformImageRef.current = clLogo;
      break;
    case 'rvt':
      platformImageRef.current = rvtLogo;
      break;
    case 'rvuniverse':
      platformImageRef.current = rvUniverseLogo;
      break;
    case 'rvtrader':
      platformImageRef.current = rvTraderLogo;
      break;
    default:
      platformImageRef.current = null;
      break;
  }

  // ========================================
  // UseEffects
  // ========================================

  useEffect(() => {
    if (didRenderRef.current) return;

    if (listing.data.archived?.some(view => view.uid === user.uid)) {
      const row = document.getElementById(`listing-row-${listing.key}`);
      if (row) {
        row.classList.add('hidden');
      }
    }

    didRenderRef.current = true;
  }, []);

  return (
    <tr id={`listing-row-${listing.key}`} className={`listing-row transition-opacity duration-500 relative text-left bg-primary w-full text-bone`}>

      <td className='bg-secondary'>
        { !isViewed && <div onClick={(event) => handleLinkClick(event, false)} className="p-1 -ml-1 bg-tertiary rounded-full" /> }
      </td>

      <td className='rounded-md px-2 max-w-fit relative'>
        <div className='h-full w-fit flex flex-row items-center justify-center text-bone fill-bone gap-1'>

          <button onClick={event => handleLinkClick(event)}><Link className='w-6 h-6 hover:text-tertiary transition' /></button>

          <button>
            <Archive
              className={`${showArchived ? 'hover:fill-c-green-light' : 'hover:fill-c-red-light'} w-6 h-6 transition`}
              onClick={e => handleArchiveClick(e)}
              id='archive-button'
            />
          </button>

          <button onMouseEnter={handleClaimHover}>
            {isClaimed ? (
              isClaimed === 'self' ? (
                <LockClosed
                  className='w-6 h-6 hover:fill-c-red-light transition fill-tertiary'
                  onClick={() => handleClaimClick(listing)}
                />
              ) : (
                <div className="relative">
                  <div className="group inline-block">
                    <LockClosed
                      className='w-6 h-6 transition fill-secondary cursor-default'
                    />
                    <span className="w-24 z-50 absolute -top-10 left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs text-white bg-black-light rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none">
                      Claimed by {user ? listing.data.claimed.filter(claim => claim.org === user.org)[0]?.fullName : 'Unknown'}
                    </span>
                  </div>
                </div>
              )
            ) : (
              <LockOpen
                className="w-6 h-6 hover:fill-tertiary transition"
                onClick={() => handleClaimClick(listing)}
              />
            )}
          </button>
        </div>
        
        {/* Popup for archive */}
        <div id="archive-form" className={`${!isArchivePopupOpen && 'hidden'} absolute top-0 left-16 p-2 rounded-lg bg-secondary shadow-lg ring-1 ring-c-red-light w-52 z-10`}>

          {/* Div that takes up whole page and closes archive popup */}
          <div
            className="fixed w-[100vw] h-[100vh] top-0 left-0 -z-50"
            onClick={() => setIsArchivePopupOpen(false)}
          />

          <form className="flex w-full flex-col" onSubmit={handleArchiveSubmit}>
            <div className="flex flex-wrap w-full gap-1 justify-center mb-2">
              {reasons.map((reason) => (
                <button
                  key={reason}
                  type="button"
                  onClick={() => toggleReason(reason)}
                  className={
                    `p-2 rounded-md w-fit text-bone shadow-sm
                    ${selectedReasons.includes(reason) ? 'bg-c-red-light hover:ring-1 hover:ring-primary' : 'bg-primary  hover:ring-1 hover:ring-c-red-light transition'}`
                  }
                >
                  {reason}
                </button>
              ))}
            </div>

            <hr className="mb-2"></hr>

            <div className="flex gap-1 w-full">
              <button type="submit" className="flex-grow p-2 rounded-lg bg-c-red hover:bg-c-red-light text-bone font-semibold transition shadow-sm">Archive</button>
              <button type="button" className="flex-grow p-2 rounded-lg bg-primary hover:bg-primary-light ring-primary ring-1 text-bone font-semibold transition shadow-sm" onClick={() => setIsArchivePopupOpen(false)}>Cancel</button>
            </div>
          </form>

          {/* Explanation */}
          <p className="text-xs text-bone text-left mt-1">You are the only person that will not be able to see this post. Luke will use this data to help refine what vehicles {user.org} sees.</p>
          <p className="text-xs text-bone text-left mt-1">You are able to unarchive this listing later if you so choose.</p>
        </div>
      </td>

      <td className='rounded-md p-2'>
        <img
          className="min-w-8 rounded-lg"
          src={platformImageRef.current} 
          alt={`${listing.data.platform} logo`}
          loading="lazy"
        />
      </td>

      {imageUrlSmallRef.current ? (
        <td className="relative rounded-md min-w-12 w-12 h-12 overflow-hidden">
          <img
            src={imageUrlSmallRef.current}
            alt={listing.data.makemodel}
            className="absolute inset-0 w-full h-full object-cover min-w-12"
            loading="lazy"
          />
        </td>
      ) : (
        <td className='rounded-md overflow-hidden'>
          <Placeholder width='48px' height='48px' className="bg-primary" />
        </td>
      )}

      <td className='rounded-md px-2 text-nowrap'>{listing.data.price === -1 ? 'Call for Price' : numberFormatter.format(listing.data.price).slice(0, -3)}</td>
      <td className='rounded-md px-2'>{listing.data.year || 'N/A'}</td>
      <td className='rounded-md px-2 text-wrap'>{capitalizeWords(listing.data.makemodel)}</td>
      <td className='rounded-md px-2 text-nowrap'>
        {
          listing.data.miles !== null
            ? numberFormatter.format(listing.data.miles).slice(1, -3)
            : listing.data.condition === 'new'
              ? '0'
              : 'N/A'
        }
        </td>
      <td className='rounded-md px-2 text-nowrap'>{truncateString(capitalizeWords(listing.data.rv_type), 7)}</td>
      {/* <td className='rounded-md px-2 text-nowrap'>N/A</td> */}
      <td className='rounded-md px-2 cursor-pointer' onClick={() => listing.data.platform === 'craigslist' ? handleMapClick(listing) : null}>
        {capitalizeWords(listing.data.city)}
      </td>
      <td className='rounded-md px-2 cursor-pointer' onClick={() => listing.data.platform === 'craigslist' ? handleMapClick(listing) : null}>
        {capitalizeWords(listing.data.state)}
      </td>
      <td className={`${listing.data.time_ago <= 4 ? 'bg-tertiary text-primary' : 'bg-primary text-bone'} rounded-md px-2 text-nowrap`}>{formatTimeAgo(listing.data.time_ago)}</td>
    </tr>
  );
}