import React, { useEffect, useState } from 'react';
import { user, db } from '../firebase';
import { ReactComponent as LogsIcon } from '../img/logs.svg';
import { ReactComponent as RefreshIcon } from '../img/refresh.svg';
import { collection, getDocs, query, where, Timestamp } from 'firebase/firestore';
import UniqueLoginsGraph from '../components/graphs/UniqueLoginsGraph';
import TotalLoginsGraph from '../components/graphs/TotalLoginsGraph';
import ListingsClaimedGraph from '../components/graphs/ListingsClaimedGraph';


export default function LogsPage() {
  // ==========================
  // State
  // ==========================
  const [org, setOrg] = useState(user.org);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isRefreshDebounce, setIsRefreshDebounce] = useState(false);
  const [didFetch, setDidFetch] = useState(false);
  const [uniqueLogins, setUniqueLogins] = useState(0);
  const [listingsClaimed, setListingsClaimed] = useState(0);
  const [listingsArchived, setListingsArchived] = useState(0);
  const [daysAgo, setDaysAgo] = useState(1);
  const [uniqueLoginsData, setUniqueLoginsData] = useState([]);
  const [dailyTotalLoginsData, setDailyTotalLoginsData] = useState([]);
  const [dailyListingsClaimedData, setDailyListingsClaimedData] = useState([]);

  // ==========================
  // Functions
  // ==========================

  // Fetch logs for the last 1 day
  async function fetchDailyLogs() {
    const logsRef = collection(db, 'logs');

    const startOfDay = new Date();
    startOfDay.setDate(startOfDay.getDate() - daysAgo);
    startOfDay.setHours(0, 0, 0, 0);
    const startTimestamp = Timestamp.fromDate(startOfDay);

    const dailyLogsQuery = query(
      logsRef,
      where('org', '==', org),
      where('timestamp', '>=', startTimestamp)
    );

    const logsSnapshot = await getDocs(dailyLogsQuery);

    const sortedLogs = logsSnapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .sort(
        (a, b) =>
          b.timestamp.seconds - a.timestamp.seconds ||
          b.timestamp.nanoseconds - a.timestamp.nanoseconds
      );

    setLogs(sortedLogs);
  }

  // Fetch aggregated data based on daysAgo
  async function fetchAggregatedData() {
    const logsRef = collection(db, 'logs');

    const startDate = new Date();
    startDate.setDate(startDate.getDate() - daysAgo);
    startDate.setHours(0, 0, 0, 0);
    const startTimestamp = Timestamp.fromDate(startDate);

    // Queries for different event types
    const loginQuery = query(
      logsRef,
      where('org', '==', org),
      where('event', '==', 'login'),
      where('timestamp', '>=', startTimestamp)
    );

    const claimQuery = query(
      logsRef,
      where('org', '==', org),
      where('event', 'in', ['claim', 'unclaim']),
      where('timestamp', '>=', startTimestamp)
    );

    const archiveQuery = query(
      logsRef,
      where('org', '==', org),
      where('event', 'in', ['archive', 'unarchive']),
      where('timestamp', '>=', startTimestamp)
    );

    // Fetch all queries concurrently
    const [loginSnapshot, claimSnapshot, archiveSnapshot] = await Promise.all([
      getDocs(loginQuery),
      getDocs(claimQuery),
      getDocs(archiveQuery),
    ]);

    // ==========================
    // Calculate Unique Logins
    // ==========================
    const uniqueUsers = new Set();
    loginSnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.user) {
        uniqueUsers.add(data.user);
      }
    });
    setUniqueLogins(uniqueUsers.size);

    // ==========================
    // Calculate Daily Unique Logins
    // ==========================
    const dailyUniqueMap = {};

    loginSnapshot.forEach((doc) => {
      const log = doc.data();
      const logDate = log.timestamp.toDate();
      const dateKey = logDate.toLocaleDateString('en-US'); // Formats as MM/DD/YYYY

      if (!dailyUniqueMap[dateKey]) {
        dailyUniqueMap[dateKey] = new Set();
      }

      if (log.user) {
        dailyUniqueMap[dateKey].add(log.user);
      }
    });

    const uniqueLoginsGraphData = Object.keys(dailyUniqueMap)
      .map((date) => ({
        date,
        count: dailyUniqueMap[date].size,
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    setUniqueLoginsData(uniqueLoginsGraphData);

    // ==========================
    // Calculate Daily Total Logins
    // ==========================
    const dailyTotalLoginsMap = {};

    loginSnapshot.forEach((doc) => {
      const log = doc.data();
      const logDate = log.timestamp.toDate();
      const dateKey = logDate.toLocaleDateString('en-US'); // Formats as MM/DD/YYYY

      if (!dailyTotalLoginsMap[dateKey]) {
        dailyTotalLoginsMap[dateKey] = 0;
      }

      dailyTotalLoginsMap[dateKey] += 1; // Increment total logins
    });

    const totalLoginsGraphData = Object.keys(dailyTotalLoginsMap)
      .map((date) => ({
        date,
        count: dailyTotalLoginsMap[date],
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    setDailyTotalLoginsData(totalLoginsGraphData);

    // ==========================
    // Calculate Listings Claimed
    // ==========================
    const dailyListingsClaimedMap = {};
    let totalClaims = 0;
    let totalUnclaims = 0;

    claimSnapshot.forEach((doc) => {
      const log = doc.data();
      const logDate = log.timestamp.toDate();
      const dateKey = logDate.toLocaleDateString('en-US'); // Formats as MM/DD/YYYY

      if (!dailyListingsClaimedMap[dateKey]) {
        dailyListingsClaimedMap[dateKey] = 0;
      }

      if (log.event === 'claim') {
        dailyListingsClaimedMap[dateKey] += 1;
        totalClaims += 1;
      } else if (log.event === 'unclaim') {
        dailyListingsClaimedMap[dateKey] -= 1;
        totalUnclaims += 1;
      }
    });

    // Ensure no negative counts
    Object.keys(dailyListingsClaimedMap).forEach((date) => {
      if (dailyListingsClaimedMap[date] < 0) {
        dailyListingsClaimedMap[date] = 0;
      }
    });

    const listingsClaimedGraphData = Object.keys(dailyListingsClaimedMap)
      .map((date) => ({
        date,
        count: dailyListingsClaimedMap[date],
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    setDailyListingsClaimedData(listingsClaimedGraphData);

    // ==========================
    // Calculate Total Listings Claimed
    // ==========================
    const netListingsClaimed = totalClaims - totalUnclaims;
    setListingsClaimed(netListingsClaimed >= 0 ? netListingsClaimed : 0);

    // ==========================
    // Calculate Listings Archived
    // ==========================
    const archives = archiveSnapshot.docs.filter(
      (doc) => doc.data().event === 'archive'
    ).length;
    const unarchives = archiveSnapshot.docs.filter(
      (doc) => doc.data().event === 'unarchive'
    ).length;
    const netListingsArchived = archives - unarchives;
    setListingsArchived(netListingsArchived >= 0 ? netListingsArchived : 0);
  }

  // Combined fetch function
  async function fetchLogsAndData() {
    setLoading(true);
    try {
      await Promise.all([fetchDailyLogs(), fetchAggregatedData()]);
      setLoading(false);
      setDidFetch(true);

      // Debounce refresh state
      setIsRefreshDebounce(true);
      const timeoutId = setTimeout(() => {
        setIsRefreshDebounce(false);
        setDidFetch(false);
      }, 4000);

      // Cleanup timeout on unmount or re-run
      return () => clearTimeout(timeoutId);
    } catch (error) {
      console.error('Error fetching logs and data:', error);
      setLoading(false);
    }
  }

  function handleRefreshClick() {
    fetchLogsAndData();
  }

  // ==========================
  // useEffects
  // ==========================

  // ==== Update org state when user.org changes ====
  useEffect(() => {
    if (user.org !== org) {
      setOrg(user.org);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.org]);

  // ==== Fetch logs and aggregated data when org, daysAgo change ====
  useEffect(() => {
    fetchLogsAndData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org, daysAgo]);

  return (
    <section id="logs-page" className="w-full h-[100vh] p-2 overflow-y-auto">
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <p className="text-4xl text-bone font-semibold">Loading logs...</p>
        </div>
      ) : (
        <div className="flex flex-col flex-nowrap text-bone">
          {/* Header Section */}
          <div className="flex items-center justify-between px-4 mb-4">
            <div className="flex items-center gap-4 ml-4">
              <h1 className="text-center text-4xl font-semibold">
                {user.org === 'johnsonrv' ? 'Johnson RV' : user.org}
              </h1>
              <LogsIcon className="w-16 h-16 fill-bone" />
            </div>

            {/* DaysAgo Buttons */}
            <div className="ring-1 ring-primary rounded-lg">
              <button
                className={`py-1 px-2 transition-colors font-semibold rounded-tl-lg rounded-bl-lg ${
                  daysAgo === 1
                    ? 'bg-tertiary text-secondary cursor-default'
                    : 'bg-secondary text-bone hover:bg-tertiary hover:text-secondary'
                }`}
                onClick={() => {
                  setDaysAgo(1);
                }}
              >
                Today
              </button>
              <button
                className={`py-1 px-2 transition-colors font-semibold ${
                  daysAgo === 7
                    ? 'bg-tertiary text-secondary cursor-default'
                    : 'bg-secondary text-bone hover:bg-tertiary hover:text-secondary'
                }`}
                onClick={() => {
                  setDaysAgo(7);
                }}
              >
                Last 7 days
              </button>
              <button
                className={`py-1 px-2 transition-colors font-semibold rounded-tr-lg rounded-br-lg ${
                  daysAgo === 30
                    ? 'bg-tertiary text-secondary cursor-default'
                    : 'bg-secondary text-bone hover:bg-tertiary hover:text-secondary'
                }`}
                onClick={() => {
                  setDaysAgo(30);
                }}
              >
                Last 30 days
              </button>
            </div>
          </div>

          {/* Important Info */}
          <div className="flex flex-wrap mb-4 w-full items-center justify-center gap-4">
            <div className="flex flex-col flex-grow bg-primary rounded-lg items-center text-center p-2">
              <h3 className="text-lg">Unique logins</h3>
              <p className="text-4xl">{uniqueLogins}</p>
            </div>
            <div className="flex flex-col flex-grow bg-primary rounded-lg items-center text-center p-2">
              <h3 className="text-lg">Listings claimed</h3>
              <p className="text-4xl">{listingsClaimed}</p>
            </div>
            <div className="flex flex-col flex-grow bg-primary rounded-lg items-center text-center p-2">
              <h3 className="text-lg">Listings archived</h3>
              <p className="text-4xl">{listingsArchived}</p>
            </div>
          </div>

          {/* Graphs Section */}
          {daysAgo > 1 && (
            <div className="flex gap-4 mb-4 flex-wrap">
              {/* Daily Unique Logins */}
              <UniqueLoginsGraph data={uniqueLoginsData} />

              {/* Daily Total Logins */}
              <TotalLoginsGraph data={dailyTotalLoginsData} />

              {/* Daily Listings Claimed */}
              <ListingsClaimedGraph data={dailyListingsClaimedData} />
            </div>
          )}

          {/* Refresh and Logs Table */}
          <div className="relative">
            {/* Refresh Button */}
            <div className="absolute top-2 right-2 flex gap-2 items-center">
              <p
                className={`${
                  didFetch ? 'opacity-100' : 'opacity-0 pointer-events-none'
                } transition-opacity duration-300 bg-c-green-light px-1 rounded-lg text-primary font-semibold`}
              >
                Logs updated!
              </p>
              <button
                className={`${
                  isRefreshDebounce && 'opacity-50 pointer-events-none'
                } transition-transform hover:rotate-180 duration-300`}
                onClick={handleRefreshClick}
                disabled={isRefreshDebounce} // Disable button during debounce
              >
                <RefreshIcon className="w-6 h-6" />
              </button>
            </div>

            {/* Logs Table */}
            <table className="min-w-full divide-y divide-secondary mb-2">
              <thead className="bg-primary">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Timestamp
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    User
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Event
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Details
                  </th>
                </tr>
              </thead>

              <tbody className="bg-primary divide-y divide-secondary">
                {logs.map((log) => {
                  const details = log.details
                    ? JSON.stringify(log.details)
                        .replaceAll(',', ', ')
                        .replaceAll(':', ': ')
                    : '';
                  return (
                    <tr
                      key={`log-${log.user}-${log.timestamp.seconds}-${log.timestamp.nanoseconds}`}
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        {log.timestamp.toDate().toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {log.fullName}
                      </td>
                      <td className="px-6 py-4">{log.event}</td>
                      <td className="px-6 py-4">{details}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </section>
  );
}