import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, addDoc, collection, Timestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Firebase config
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

// Initialize Firebase only once
const app = initializeApp(firebaseConfig);

// Initialize Firestore and Auth
const db = getFirestore(app);
const auth = getAuth(app);

// Custom user object to hold additional properties
let user = { org: 'johnsonrv' };

// Fetch user data from Firestore and add it to `user` object
const initializeUser = async () => {
  const currentUser = auth.currentUser;

  if (!currentUser) {
    console.error('[-] No current user');
    return null;
  }

  try {
    const userDocRef = doc(db, "users", currentUser.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      user = {
        ...userDoc.data(),
        uid: currentUser.uid,
        fullName: userDoc.data().fullName || 'Unknown',
        org: 'johnsonrv' // Default organization
      };
      return user;
    } else {
      console.error('[-] No such user document!');
      return null;
    }
  } catch (error) {
    console.error('[-] Error fetching user object:', error);
    return null;
  }
};


function logEvent(eventType, details={}) {
  const invalidUids = [
    'Hlly4j2vvwgW9MYowSayfnaYL213', // Kels dad
    'LfPrlG8u83grPjg6QPuTqKIVKRy2', // Kels
    'O2cUcapR3LSmzT8J63ZGbZrduWg2', // Jesse
    'aSsb8FSN02WYtTEJVf7mmmH9XMK2', // Trevor
    'rUrTSPESzxcQG3Cjhj794PQPqu12', // Test acc
    'riDPjGdkJJgFFYDWgTPeVnigF653' // Luke
  ];

  const validEvents = [
    'login',
    'archive',
    'unarchive',
    'claim',
    'unclaim',
    'toggle_map',
    'toggle_filter',
    'toggle_tile_view',
    'sort',
    'link_click',
    'get_saved_filters',
    'save_filter',
    'delete_filter',
    'update_filter',
    'load_filter',
  ]
  
  if (!auth.currentUser) {
    console.error('[-] No current user');
    return;
  }
  
  if (!eventType) {
    console.error('[-] No event type provided');
    return;
  }
  
  if (validEvents.includes(eventType) === false) {
    console.error('[-] Invalid event type provided');
    return;
  }

  if (invalidUids.includes(auth.currentUser.uid)) {
    console.error('[-] Skipping logs for admin user');
    return;
  }

  // Log the user login event to firestore logs collection
  const logsRef = collection(db, 'logs');

  addDoc(logsRef, {
    user: auth.currentUser.uid,
    event: eventType,
    timestamp: Timestamp.now(),
    fullName: user.fullName,
    org: user.org,
    details: details
  }).then((docRef) => {
    console.log(`[+] Logged ${eventType} event with ID: ${docRef.id}`);
    console.log(`[+] Event details: ${JSON.stringify(details)}`);
  }).catch((error) => {
    console.error('[-] Error adding document:', error);
  });
}


// Export the db, auth, and user object
export { db, auth, initializeUser, user, logEvent };
