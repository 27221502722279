import AdCard from "../components/AdCard";
import { db, logEvent, user } from "../firebase"
import { useState, useEffect, useRef } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { ReactComponent as ListView } from '../img/view-list.svg'
import { ReactComponent as TileView } from '../img/view-grid.svg'
import { ReactComponent as LockClosed } from '../img/lock-closed.svg';
import { ReactComponent as LockOpen } from '../img/lock-open.svg';
import { ReactComponent as Archive } from '../img/archive.svg';
import LogoWork from '../img/logo-work.png'
import AdTable from '../components/AdTable'
import FilterSection from "../components/FilterSection";
import blankFilter from '../config/blankFilter.json';
import MapComponent from "../components/MapComponent";


// Creating circles on maps in react w/ radius
// https://stackoverflow.com/questions/55393614/create-google-maps-circle-and-set-its-radius-in-react

export default function LeadsPage() {
  const [center, setCenter] = useState({ lat: 47.6061, lng: -122.3328 });
  const [zoom, setZoom] = useState(4)
  const [listings, setListings] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);
  const [isListingsLoading, setListingsLoading] = useState(true);
  const [isTile, setIsTile] = useState(false);
  const [filters, setFilters] = useState(blankFilter);
  const [isMapMode, setIsMapMode] = useState(false);
  const [filteredVIds, setFilteredVIds] = useState([]);
  const [isMapCollapsed, setIsMapCollapsed] = useState(true);
  const [showClaim, setShowClaim] = useState('all');
  const prevShowClaimRef = useRef(null);
  const circleRef = useRef(null);
  const hasFetchedListings = useRef(false);
  const [daysLoaded, setDaysLoaded] = useState(1);
  const [optimisticDaysLoaded, setOptimisticDaysLoaded] = useState(daysLoaded);
  const [allListingsLoaded, setAllListingsLoaded] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const debounceTimeoutRef = useRef(null);
  const prevListingRef = useRef(null);
  const prevFiltersRef = useRef(null);

  const maxDate = new Date("October 8, 2024 00:00:00 UTC-6");

  function formatTimeAgo(d) {
    const days = Math.floor(d / 24);
    const hours = d % 24;
  
    let parts = [];
    
    if (days > 0) {
      parts.push(`${days} day${days > 1 ? 's' : ''}`);
    }
    
    if (hours > 0) {
      parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
    }
  
    return parts.length > 0 ? parts.join(', ') + ' ago' : 'just now';
  }

  function handleDaysLoadedInput(e) {
    const days = Math.max(1, parseInt(e.target.value) || 1);

    setOptimisticDaysLoaded(days);

    // Clear the previous timeout if it exists
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Set a new timeout
    debounceTimeoutRef.current = setTimeout(() => {
      setDaysLoaded(days);
    }, 500); // 0.5 second debounce
  }
  
  function filterListings(listings) {
    if (!listings || listings.length === 0) return [];
  
    return listings.filter(listing => {
      return Object.keys(filters).every(key => {
        // Skip filtering for location keys 'lat' and 'lng'
        if (key === 'lat' || key === 'lng') return true;
  
        // If the filter is null, undefined, or empty, include all listings for that key
        if (listing.data[key] == null) return true;

        if (listing.data[key] === '') return true;
  
        // If filter for this key is not set or is empty (i.e., the user hasn't selected anything for this filter)
        if (!filters[key] || (Array.isArray(filters[key]) && filters[key].length === 0)) return true;
  
        // If the filter is a specific value (e.g., class A), only include listings that match the value
        if (Array.isArray(filters[key])) {
          // If the listing value is null, include it if the filter array allows it
          return filters[key].includes(listing.data[key]) || listing.data[key] == null;
        }
  
        // Handle range filtering (object with min/max)
        if (typeof filters[key] === 'object' && filters[key] !== null && 'min' in filters[key] && 'max' in filters[key]) {
          if (listing.data.makemodel === 'national rv tradewinds') console.log(listing.data.year === '')
          const min = filters[key].min ?? 0; // default to 0 if min is null
          const max = filters[key].max ?? 99999999999; // default to a very high number if max is null
          const value = listing.data[key];
          return min <= value && value <= max;
        }
  
        // Handle simple equality filtering (strings, numbers, etc.)
        return filters[key] === listing.data[key];
      });
    });
  }
  
  function timeAgo(date) {
    const now = new Date();
    const diffInMs = now - date;
    return Math.floor(diffInMs / (1000 * 60 * 60)); // Convert milliseconds to hours
  }

  const handleMapClearClick = () => {
    if (circleRef.current !== null) circleRef.current.setMap(null);
    setFilteredVIds([]);
  }

  useEffect(() => {
    let filtered = filterListings(listings);
    if (showClaim === 'open') {
      filtered = filtered.filter(listing => listing.data.claimed?.length === 0 || listing.data.claimed === undefined);
    }
    filtered = filtered.filter(listing => !listing.data.archived?.includes(user.uid))
    if (filteredVIds !== null && filteredVIds.length !== 0) {
      filtered = filtered.filter(l => {
        return filteredVIds.includes(l.data.v_id);
      });
    }
    setFilteredListings(filtered);
  }, [filters, listings, filteredVIds]);

  async function fetchListings(signal=null, daysAgo=1, filters=[]) {
    try {
      const listingCollection = collection(db, 'listings');
  
      // Get listings from the specified number of days ago
      const endDate = new Date();
      endDate.setDate(endDate.getDate()-(daysAgo ? daysAgo : 1));
  
      let listingQuery = query(listingCollection);

      if (daysAgo) {
        listingQuery = query(listingQuery, where('timestamp', '>=', endDate));
      }

      filters.forEach((filter) => {
        listingQuery = query(listingQuery, where(...filter));
      });
  
      // Fetch docs from Firestore
      const listingSnapshot = signal
        ? await getDocs(listingQuery, { signal })
        : await getDocs(listingQuery);
  
      const listingData = [];
      listingSnapshot.forEach(doc => {
        if (doc.data().datetime_posted === undefined) {
          console.error('datetime_posted is undefined, this should never happen:', doc.data());
        }
        const time_ago = timeAgo(doc.data().timestamp.toDate());
        listingData.push({
          data: {
            ...doc.data(),
            time_ago: time_ago,
            makemodel: doc.data().platform !== 'craigslist'
              ? `${doc.data().manufacturer || ''} ${doc.data().make || ''} ${doc.data().model || ''}`
              : doc.data().makemodel
          },
          key: doc.id
        });
      });
      if (endDate <= maxDate) {
        setAllListingsLoaded(true);
      } else{
        setListings(listingData);
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error fetching listings:', error);
      }
    } finally {
      setListingsLoading(false);
      return;
    }
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setOptimisticDaysLoaded(daysLoaded);

    if (showClaim === 'all' || showClaim === 'open') {
      fetchListings(signal, daysLoaded);
    } else if (showClaim === 'self') {
      fetchListings(
        signal,
        null,
        [
          ['claimed', 'array-contains', { ...user }],
        ]
      );
    }

    hasFetchedListings.current = true;

    return () => controller.abort();
  }, [showClaim, daysLoaded]);

  async function handleArchiveToggleClick() {
    const filterPicker = document.getElementById('filter-picker');
    setShowArchived(prev => {
      if (!prev) {
        filterPicker && filterPicker.classList.add('hidden');

        prevListingRef.current = listings;
        prevFiltersRef.current = filters;
        setFilters(async prev => {
          await fetchListings(null, null, [
            ['archived', 'array-contains', { ...user }]
          ]).then(() => {
            const timeoutId = setTimeout(() => {
              Array.from(document.getElementsByClassName('listing-row')).map(d => {
                d.classList.remove('hidden');
                d.classList.remove('opacity-0');
              });
              clearTimeout(timeoutId);
            }, 500);
          });
          return blankFilter;
        });
      } else {
        filterPicker && filterPicker.classList.remove('hidden');
        
        // Set the filtered listings to the listings that were fetched before the archive filter was applied
        setFilters(prevFiltersRef.current);
        setListings(prevListingRef.current);

        // Hide archived listings (this happens with the previous state of listings due to the nature of state updates)
        Array.from(document.getElementsByClassName('listing-row')).map(d => {
          if (!d.classList.contains('hidden')) d.classList.add('hidden');
          else d.classList.remove('hidden');
        });
      }

      return !prev;
    });
  }

  return (
    <div className="relative w-full flex flex-col gap-2 flex-nowrap p-2 h-screen min-h-screen">
      {/* Map Area */}
      {!isMapCollapsed &&
        <MapComponent zoom={zoom} filteredListings={filteredListings} setFilteredListings={setFilteredListings} center={center} setCenter={setCenter} isMapMode={isMapMode} circleRef={circleRef} setFilteredVIds={setFilteredVIds} />
      }

      {/* Map Filters */}
      <div id="map-filter-section" className="w-full bg-primary flex flex-row gap-2 p-2 rounded-md justify-between items-center text-bone">
        <div className="flex flex-row gap-2">
          {/* Grid vs. Tile View */}
          <button
            onClick={() => {
              setIsTile(!isTile);
              logEvent('toggle_tile_view', { isTile: !isTile });
            }}
            className="flex gap-2 text-bone font-medium justify-center p-1 bg-secondary rounded-md fill-white hover:fill-tertiary hover:shadow-md"
          >
            {isTile
              ? <ListView className="hover:shadow-md transition h-6 w-6"/>
              : <TileView className="transition h-6 w-6"/>
            }
          </button>
          <div className="flex gap-2">
            {/* Filter By Map button */}
            {/* <button
              onClick={() => {
                setIsMapMode(prev => !prev);
                if (isMapCollapsed) setIsMapCollapsed(false);
              }}
              className="bg-secondary py-1 px-2 rounded-md hover:bg-secondary-dark hover:shadow-lg transition"
            >
              {isMapMode ? 'Apply' : 'Filter by location'}
            </button> */}

            {/* Clear map filters */}
            {filteredVIds.length !== 0 && (
              <button
                onClick={() => handleMapClearClick()}
                className="bg-secondary py-1 px-2 rounded-md hover:bg-secondary-dark shadow-md transition"
              >
                Clear filter
              </button>
            )}
            <button
              onClick={() => {
                setIsMapCollapsed(prev => !prev)
                logEvent('toggle_map', { isMapCollapsed: !isMapCollapsed });
              }}
              className="bg-secondary py-1 px-2 rounded-md hover:bg-secondary-dark shadow-md transition"
            >
              {isMapCollapsed
                ? 'Show '
                : 'Hide '
              }
              map
            </button>
          </div>
        </div>

        <div className="flex gap-2">

          {/* Show archive button */}
          <div className="flex items-center justify-center">
            <div className="relative group">
              <button
                className={
                  `rounded-md shadow-md p-1 transition group
                  ${showArchived
                    ? 'bg-c-red'
                    : 'bg-secondary text-bone hover:bg-c-red-light'
                  }`
                }
                onClick={handleArchiveToggleClick}
              >
                <Archive className="w-6 h-6 transition" />
              </button>
              <span className="text-center w-24 z-50 absolute top-8 left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs text-white bg-black-light rounded-md hidden group-hover:block opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                {showArchived ? 'Hide archived' : 'Show archived'}
              </span>
            </div>
          </div>
          
          {/* Claiming filter buttons */}
          <div className={`${showArchived && 'pointer-events-none opacity-50'} flex items-center gap-2 shadow-md rounded-md`}>
            <div className="flex items-center bg-secondary rounded-md">
              <button
                className={
                  `py-1 px-2 transition-colors font-semibold rounded-tl-md rounded-bl-md
                  ${showClaim === 'all' 
                    ? 'bg-tertiary text-secondary cursor-default'
                    : 'bg-secondary text-bone hover:bg-tertiary hover:text-secondary'}`
                  }
                  onClick={() => {
                    prevShowClaimRef.current = showClaim;
                    setShowClaim('all')
                  }}
              >
                All
              </button>
              <button
                className={`transition-colors border-l-2 border-r-2 relative max-h-8
                  ${showClaim === 'open' 
                    ? 'bg-tertiary text-secondary cursor-default'
                    : 'bg-secondary text-bone hover:bg-tertiary hover:text-secondary'}`}
                onClick={() => {
                  prevShowClaimRef.current = showClaim;
                  setShowClaim('open')
                }}
              >
                <div className="py-1 px-2 group inline-block">
                  <LockOpen className="w-6 h-6" />
                  <span className="w-24 z-50 absolute top-8 left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs text-white bg-black-light rounded-md hidden group-hover:block opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                    Unclaimed listings
                  </span>
                </div>
              </button>
              <button
                className={`transition-colors relative rounded-tr-md rounded-br-md max-h-8
                  ${showClaim === 'self' 
                    ? 'bg-tertiary text-secondary cursor-default'
                    : 'bg-secondary text-bone hover:bg-tertiary hover:text-secondary'}`}
                onClick={() => {
                  prevShowClaimRef.current = showClaim;
                  setShowClaim('self')
                }}
              >
                <div className="py-1 px-2 group inline-block">
                  <LockClosed className={`w-6 h-6 group-hover:fill-secondary ${showClaim === 'self' ? 'fill-secondary' : 'fill-tertiary'}`} />
                  <span className="w-24 z-50 absolute top-8 left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs text-white bg-black-light rounded-md hidden group-hover:block opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                    Your claimed listings
                  </span>
                </div>
              </button>
            </div>
          </div>

          {/* Days ago picker */}
          <div className={`${showArchived && 'pointer-events-none opacity-50'} flex items-center gap-2 shadow-lg rounded-md px-2 bg-secondary`}>
            <label htmlFor='days-ago-input'>Days Ago</label>
            <input
              id="days-ago-input"
              onChange={e => handleDaysLoadedInput(e)}
              value={optimisticDaysLoaded} type="number"
              placeholder="Days ago"
              className="max-w-16 bg-primary text-bone px-2 rounded-md"
              min={1}
            />
          </div>

          {/* Filtering section */}
          <div className={`${showArchived ? 'pointer-events-none opacity-50' : ''}`}>
            <FilterSection filters={filters} setFilters={setFilters} />
          </div>
        </div>
      </div>

      {/* Listings */}
      {!isListingsLoading
        ? isTile
          ? <div className="relative w-full flex flex-row flex-wrap gap-4">
              {filteredListings.map(listing => {
                return <AdCard key={listing.key} listing={listing.data} setCenter={setCenter} formatTimeAgo={formatTimeAgo} />;
              })}
            </div>
          : <AdTable listings={filteredListings} setCenter={setCenter} setZoom={setZoom} setIsMapCollapsed={setIsMapCollapsed} formatTimeAgo={formatTimeAgo} allListingsLoaded={allListingsLoaded} daysLoaded={daysLoaded} setDaysLoaded={setDaysLoaded} showArchived={showArchived} />
        : <div className="w-full flex flex-col items-center justify-start text-bone text-xl">
            <img src={LogoWork} alt="Logo Working" className='w-48' />
            <p className="w-fit h-fit">Loading...</p>
          </div>
      }
    </div>
  );
}
